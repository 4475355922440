import React from 'react'
import './Features.scss'
export const Features = (props) => {
    return (
        <div className={`feature ${props.reverse ? 'reverse' : ''}`}>
            <div className='feature-img'>
                <img src={props.feature1} alt="" />
            </div>
            <div className='feature-text'>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </div>
    )
}
