import React, { useEffect, useRef, useState, } from 'react'
import mainLogo from '../../assets/images/main_icon.png'
import ham from '../../assets/images/menu1.png'
import close from '../../assets/images/close.png'

import './Header.scss'
import '../../assets/scss/style.scss'
import { Link } from 'react-router-dom'
export const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    console.log('clicked');
  };
  return (

    <header className={hasScrolled ? 'scrolled' : ''} ref={dropdownRef}>
      <div className="left">

        <img className='mainLogo' src={mainLogo} alt="" />
        <h2>ChatVerse</h2>

      </div>
      <div className="middle">
        <nav>
          <ul>
            <li>Home</li>
            <li>Features</li>
            <li>Contacts</li>
          </ul>

        </nav>
      </div>

      <div className="right">
        <button>Download</button>
      </div>
      <img src={ham} alt="" className='ham' id="ham" onClick={toggleMenu} />

      <div className={isMenuOpen ? 'menuOpen' : 'menuNotOpen'}>

        <div className="top">
          <h3 onClick={toggleMenu} >ChatVerse</h3>
          <img src={close} alt="cross" className='closeImg' onClick={toggleMenu} />
        </div>

        <nav>
          <ul>
            <li>Home</li>
            <li>Features</li>
            <li>Contacts</li>
          </ul>
        </nav>
      </div>

    </header>
  )
}
