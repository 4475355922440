import React from 'react'
import './Hero.scss'
import downArrow from '../../assets/images/downArrowWhite.png';

export const Hero = () => {
    return (
        <section className='Hero'>
            {/* <svg className='blob' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="blur" x="0" y="0">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
                    </filter>
                </defs>
                <path class="blob-path" fill="#0D9B6E" d="M66.7,-17.6C76.1,7.1,66.1,42.2,42.2,60C18.3,77.9,-19.6,78.5,-39.9,62.1C-60.2,45.6,-62.9,12.2,-53.3,-12.8C-43.7,-37.8,-21.9,-54.3,3.4,-55.4C28.7,-56.5,57.4,-42.2,66.7,-17.6Z"
                    transform="translate(100 100)" filter="url(#blur)" />
                <path class="blob-path" fill="#0D9B6E" d="M66.7,-17.6C76.1,7.1,66.1,42.2,42.2,60C18.3,77.9,-19.6,78.5,-39.9,62.1C-60.2,45.6,-62.9,12.2,-53.3,-12.8C-43.7,-37.8,-21.9,-54.3,3.4,-55.4C28.7,-56.5,57.4,-42.2,66.7,-17.6Z"
                    transform="translate(100 100)" filter="url(#blur)" />
                <path class="blob-path" fill="#0D9B6E" d="M66.7,-17.6C76.1,7.1,66.1,42.2,42.2,60C18.3,77.9,-19.6,78.5,-39.9,62.1C-60.2,45.6,-62.9,12.2,-53.3,-12.8C-43.7,-37.8,-21.9,-54.3,3.4,-55.4C28.7,-56.5,57.4,-42.2,66.7,-17.6Z"
                    transform="translate(100 100)" filter="url(#blur)" />
                <path class="blob-path" fill="#0D9B6E" d="M66.7,-17.6C76.1,7.1,66.1,42.2,42.2,60C18.3,77.9,-19.6,78.5,-39.9,62.1C-60.2,45.6,-62.9,12.2,-53.3,-12.8C-43.7,-37.8,-21.9,-54.3,3.4,-55.4C28.7,-56.5,57.4,-42.2,66.7,-17.6Z"
                    transform="translate(100 100)" filter="url(#blur)" />
            </svg> */}

            <div className="herodiv">
                <h1>Connect And Grow With <span>ChatVerse!</span></h1>
                <button> <img src={downArrow} alt="" /> <span>Download</span></button>
            </div>
        </section>
    )
}
