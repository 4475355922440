import React from 'react'
import './Footer.scss'
import Greenicon from '../../assets/images/green-main_icon.png'
import facebook from '../../assets/images/facbook.png'
import insta from '../../assets/images/insta.png'
import x from '../../assets/images/x.png'

export const Footer = () => {
  return (
    <footer >
      <div className="footer-logo">
        <img src={Greenicon} alt="" />
        <h3>ChatVerse</h3>
        <p>Connect.Communicate.Create</p>
      </div>
      <div className="link-wrapper">
      <div className="textLinks">
        
      </div>
       <div className="textLinks">
       <div className='links'>
          <ul>
            <li className='heading'>Quick Links</li>
            <li className='inner-links'>Home </li>
            <li className='inner-links'>Features</li>
          </ul>
        </div>
        <div className='links'>
          <ul>
            <li className='heading'>Contact Us</li>
            <li className='inner-links'>Phone Number</li>
            <li className='inner-links'>Email</li>
          </ul>
        </div>
       </div>
        <div className="social-wrapper">

          <div className='social-icons'>
            <img src={facebook} alt="" />
            <img src={insta} alt="" />
            <img src={x} alt="" />
          </div>
          <div className='social-text'>
            <p>Terms of Use</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
