import React from 'react'
import { Layout } from '../../components/Layouts/Layout'
import { Hero } from '../../components/Hero/Hero'
import { Features } from '../../components/Features/Features'
import feature1 from '../../assets/images/Vector.png'
import feature2 from '../../assets/images/Vector1.png'
import feature3 from '../../assets/images/Vector2.png'
import feature4 from '../../assets/images/Vector3.png'
import feature5 from '../../assets/images/Vector4.png'
import downArrow from '../../assets/images/downArrowWhite.png'
import './Home.scss'



export const Home = () => {
  return (
    <Layout>
      <Hero />
      <section className="feature-section">
        <h2>Our Features</h2>
        <Features feature1={feature1} title="Messaging and Communication" text="Users can exchange text messages, images, and videos in individual  and professional chats." />
        <Features reverse='true' feature1={feature2} title="Professional Home Page" text="Separate from personal profiles, professionals can create a professional home page where they showcase their work, achievements, and professional updates." />
        <Features feature1={feature3} title="Security and Privacy" text="Users can control who can view their profile information, posts, and personal details." />
        <Features reverse='true' feature1={feature4} title = "Corss Platefrom Compatibility" text ="Available on iOS, Android, and web platforms for seamless access across devices." />
        <Features feature1={feature5} title = "Much More"/>

        <div className='bottom-section'>
          <button> <img src={downArrow} alt="" /> <span>Download</span></button>
          <p>Explore amazing side of social media
            with <span>ChatVerse!</span></p>
        </div>
      </section>

    </Layout>
  )
}
